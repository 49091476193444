export const roundAccurately = (
  number: string | number,
  decimalPlaces: string | number
) =>
  Number(
    Math.round(parseFloat(number + 'e' + decimalPlaces)) + 'e-' + decimalPlaces
  )

export const isDev = () => {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
}

export const IS_MAINTENANCE_MODE =
  process.env.NEXT_PUBLIC_MAINTENANCE_MODE === '1' ? true : false

type IsMintLiveProps = {
  firstEditionMintStartDate?: Date | null
  secondEditionMintStartDate?: Date | null
  firstEditionDurationInDays?: number | null
}

export function isMintLive({
  firstEditionMintStartDate,
  firstEditionDurationInDays,
  secondEditionMintStartDate,
}: IsMintLiveProps): 'FIRST_EDITION' | 'SECOND_EDITION' | false {
  if (!firstEditionMintStartDate || !firstEditionDurationInDays) {
    return false
  }

  const now = new Date()
  const firstEditionStart = new Date(firstEditionMintStartDate)
  const firstEditionEnd = new Date(firstEditionMintStartDate)
  firstEditionEnd.setDate(
    firstEditionStart.getDate() + firstEditionDurationInDays
  )

  if (firstEditionStart < now && now < firstEditionEnd) {
    return 'FIRST_EDITION'
  }

  if (!secondEditionMintStartDate) {
    return 'SECOND_EDITION'
  }

  const secondEditionStart =
    secondEditionMintStartDate && new Date(secondEditionMintStartDate)

  if (now > secondEditionStart) {
    return 'SECOND_EDITION'
  }

  return false
}
